import React, { memo } from 'react';
import { string, func, oneOf, node, bool } from 'prop-types';
import cn from 'classnames';

const Button = ({
  onClick,
  buttonStyle,
  size,
  wide,
  title,
  children,
  linkTo,
  color,
  className,
  ariaLabel,
  type,
  isDisabled,
}) => {
  const classnames = cn(
    'button',
    `button--${buttonStyle}`,
    `button--${size}`,
    `button--${color}`,
    {
      'button--wide': wide,
    },
    className
  );

  return (
    <>
      {children ? (
        <button
          // eslint-disable-next-line react/button-has-type
          type={type}
          className={classnames}
          onClick={onClick}
          aria-label={ariaLabel}
          disabled={isDisabled}
        >
          {children}
        </button>
      ) : (
        <a href={linkTo} className={classnames} onClick={onClick} aria-label={ariaLabel}>
          {title}
        </a>
      )}
    </>
  );
};

Button.propTypes = {
  title: string,
  onClick: func,
  buttonStyle: oneOf(['primary', 'secondary', 'white']),
  type: string,
  size: oneOf(['medium', 'small']),
  color: oneOf(['green', 'black']),
  children: node,
  wide: bool,
  linkTo: string,
  className: string,
  ariaLabel: string,
  isDisabled: bool,
};

Button.defaultProps = {
  wide: false,
  color: 'black',
  type: 'button',
  isDisabled: false,
};

export default memo(Button);
