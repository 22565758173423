import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'gatsby';
import cn from 'classnames';

import LogoIcon from '../Icons/LogoIcon';
import HamburgerIcon from '../Icons/HamburgerIcon';
import CloseIcon from '../Icons/CloseIcon';
import Button from '../common/Button/Button';
import config from '../../../data/SiteConfig';
import * as messages from '../../locales/en.json';
import useAnalytics from '../../hooks/useAnalytics';

const Navbar = () => {
  const { trackEvent } = useAnalytics();

  const [showScrollingStyles, setShowScrollingStyles] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleScroll = () => setShowScrollingStyles(window.pageYOffset > 0);

  useEffect(() => {
    window && window.addEventListener('scroll', handleScroll);
    return () => window && window.removeEventListener('scroll', handleScroll);
  }, []);

  ReactModal.setAppElement('#___gatsby');

  // TODO: Take into account aria-labels for future internationalization
  const logo = (
    <Link className="soc-logo" aria-label="Go to Home" to="/">
      <LogoIcon />
    </Link>
  );

  const onMenuClick = () => {
    setIsMenuOpen(true);
    trackEvent('Click Menu');
  };

  const onViewMyAccountClick = (from = 'navbar') => {
    trackEvent('Click View my Account', { Location: from });
  };

  return (
    <>
      <nav role="navigation" className={cn('header', { 'header--scrolling': showScrollingStyles })}>
        <div className="container">
          <div className="row no-gutters justify-content-between">
            {logo}
            <div className="header-cta">
              <Button
                linkTo={config.portalURL}
                buttonStyle={showScrollingStyles ? 'primary' : 'secondary'}
                size="small"
                color={showScrollingStyles ? 'black' : 'green'}
                title={messages['header.viewAccount']}
                className="hide-sm"
                onClick={() => onViewMyAccountClick('navbar')}
                ariaLabel={messages['header.viewAccount']}
              />
              <Button className="button--icon" ariaLabel="Open Menu" onClick={onMenuClick}>
                <HamburgerIcon />
              </Button>
            </div>
          </div>
        </div>
      </nav>
      <ReactModal
        isOpen={isMenuOpen}
        onRequestClose={() => setIsMenuOpen(false)}
        overlayClassName="menu-overlay"
        className="menu-content"
      >
        <div className="menu-container container">
          <div className="row">
            <div className="col-lg-6">{logo}</div>
            <div className="col-lg-6 menu-item-container">
              <Link className="menu-item" aria-label="Go to Home" to="/">
                <span className="menu-item-label">{messages['menu.home']}</span>
              </Link>
              <Link className="menu-item" aria-label="Go to Who We Are" to="/about">
                <span className="menu-item-label">{messages['menu.whoWeAre']}</span>
              </Link>
              <Link className="menu-item" aria-label="Go to Privacy Policies" to="/privacy-policy">
                <span className="menu-item-label">{messages['menu.privacy']}</span>
              </Link>
              <Link
                className="menu-item"
                aria-label="Contact Us"
                to="/contact-us"
                onClick={() => trackEvent('Click Get In Touch', { Location: 'menu' })}
              >
                <span className="menu-item-label">{messages['menu.getInTouch']}</span>
              </Link>
            </div>
            <div className="col-sm-12">
              <Button
                linkTo={config.portalURL}
                buttonStyle="primary"
                size="small"
                color="green"
                title={messages['header.viewAccount']}
                wide
                className="show-md-down"
                onClick={() => onViewMyAccountClick('menu')}
                ariaLabel={messages['header.viewAccount']}
              />
            </div>
          </div>
          <Button
            ariaLabel="Close Menu"
            className="button--icon menu-close-button"
            onClick={() => setIsMenuOpen(false)}
          >
            <CloseIcon />
          </Button>
        </div>
      </ReactModal>
    </>
  );
};

export default Navbar;
