import React from 'react';
import { Helmet } from 'react-helmet';
import { arrayOf, node, oneOfType, bool } from 'prop-types';

import config from '../../data/SiteConfig';
import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';

import '../styles/styles.scss';

const MainLayout = ({
  children,
  hideFooter,
  hideNYResidentsDisclosure,
  hideAccountOwner,
  hideNMLS,
  hideSCDisclosure,
}) => (
  <>
    <Helmet>
      <meta name="description" content={config.siteDescription} />
      <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap"
        rel="stylesheet"
        type="text/css"
        media="print"
        onLoad="this.onload=null;this.media='all'"
      />
      <html lang="en" />
    </Helmet>
    <Navbar />
    <main role="main" className="layout-content">
      {children}
    </main>
    {!hideFooter && (
      <Footer
        hideAccountOwner={hideAccountOwner}
        hideNMLS={hideNMLS}
        hideNYResidentsDisclosure={hideNYResidentsDisclosure}
        hideSCDisclosure={hideSCDisclosure}
        config={config}
      />
    )}
  </>
);

MainLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  hideFooter: bool,
  hideNYResidentsDisclosure: bool,
  hideAccountOwner: bool,
  hideNMLS: bool,
  hideSCDisclosure: bool,
};

export default MainLayout;
